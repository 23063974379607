<template>
  <div id="app">
    <img src="@/assets/logo.png" alt="clak" />
    <!-- USER EMAIL -->
    <div style="width: 100%; max-width: 320px;" v-if="!emailFounded">
      <h3>Recuperación de contraseña</h3>
      <div class="campo-email">
        <div class="campo-input">
          <label for="email">¿Cuál es tu Email?</label>
        </div>
        <div class="line-separator"></div>
        <div class="campo-input">
          <input
            type="email"
            id="email"
            v-model="email"
            placeholder="Email"
            @keypress="clearError()"
          />
        </div>
      </div>
      <button class="btn-validar-email" @click="validarEmail()" >
        <span v-if="!loading">Validar Email</span> 
        <img v-else src="@/assets/loading.gif" class="loading">
      </button>
    </div>

    <!-- NEW PASSWORD -->
    <div class="fade-left" style="width: 100%; max-width: 320px;" v-else>
      <h3>Escribe tu nueva contraseña</h3>
      <div class="campo-email">
        <div class="campo-input">
          <input
            type="password"
            v-model="password"
            placeholder="Nueva contraseña"
            :disabled="passwordSuccess"
            @keypress="clearError()"
          />
        </div>
        <div class="line-separator"></div>
        <div class="campo-input">
          <input
            type="password"
            v-model="passwordCheck"
            :disabled="passwordSuccess"
            placeholder="Repite la nueva contraseña"
            @keypress="clearError()"
          />
        </div>
      </div>
      <button class="btn-validar-email" @click="validarPassword()" :disabled="passwordSuccess">
        <span v-if="!loading">Guardar</span> 
        <img v-else src="@/assets/loading.gif" class="loading">
      </button>
    </div>

    <!-- Error Message -->
    <p v-if="error" class="error-message fade-up">
      <i class="bi bi-exclamation-circle-fill" style="margin-right: 12px"></i>
      {{ errorMessage }}
    </p>
    
    <!-- Password change successfully -->
    <p v-if="passwordSuccess" class="success-message fade-up">
      <i class="bi bi-check-circle-fill" style="margin-right: 12px"></i>
      La contraseña se ha cambiado correctamente.
    </p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      email: "",
      error: false,
      errorMessage: "",
      emailFounded: false,
      userId: null,
      loading: false,
      password: "",
      passwordCheck: "",
      passwordSuccess: false
    };
  },
  created() {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  methods: {
    // Metodo para limpiar el mensaje de error
    clearError() {
      this.error = false;
      this.errorMessage = "";
    },

    // Metodo para validar el email si es correcto
    validarEmail() {
      this.clearError();
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (regex.test(this.email)) {
        this.checkEmail();
      } else {
        this.error = true;
        this.errorMessage = "El email no es válido";
      }
    },

    validarPassword() {
      this.clearError();
      if (this.password.length < 6) {
        this.error = true;
        this.errorMessage = "La contraseña debe tener al menos 6 caracteres";
      } else if (this.password !== this.passwordCheck) {
        this.error = true;
        this.errorMessage = "Las contraseñas no coinciden";
      } else {
        this.changePassword();
      }
    },

    // Metodo para buscar el usuario en la base de datos
    async checkEmail() {
      this.loading = true;
      // Variable to wait 1 second
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      await axios
        .post("/user_get", { email: this.email })
        .then((response) => {
          this.loading = false;
          if(response.data.response) {
            this.emailFounded = true;
            this.userId = response.data.data.id_usuario;
          } else {
            this.emailFounded = false;
            this.error = true;
            this.errorMessage = "No se ha encontrado usuario con ese Email";
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = true;
          this.errorMessage = "No se ha encontrado usuario con ese Email";
        });
    },

    async changePassword() {
      this.loading = true;
      // Variable to wait 1 second
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      await delay(1000);
      await axios
        .put("/user", { id_usuario: this.userId, password: this.password })
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response.data.response) {
            this.passwordSuccess = true;
            this.password = "";
            this.passwordCheck = "";
          } else {
            
            this.error = true;
            this.errorMessage = "Error, el cambio de contraseña no se ha efectuado";
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.error = true;
          this.passwordSuccess = false;
          this.errorMessage = "Error, el cambio de contraseña no se ha efectuado";
        });
    }
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", system-ui, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#app {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #f7f6f2;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;

  img {
    width: 220px;
  }
  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 1rem;
  }

  .error-message {
    width: 100%;
    color: #e82b2b;
    font-size: 0.8rem;
    font-weight: 600;
    position: absolute;
    bottom: 3rem;
    left: 0;
  }

  .success-message {
    width: 100%;
    color: #2b8e2b;
    font-size: 0.8rem;
    font-weight: 600;
    position: absolute;
    bottom: 3rem;
    left: 0;
  }

  .btn-validar-email {
    width: fit-content;
    background-color: #f7f6f2;
    font-family: "Poppins", system-ui, sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    border: none;
    border-radius: 1rem;
    padding: 0.3rem 1rem;
    color: rgb(40, 40, 40);
    cursor: pointer;
    margin-top: 1rem;
  }

  .loading {
    height: 3rem;
    object-fit: contain;
    object-position: center;
  }

  .campo-email {
    width: 100%;
    max-width: 320px;
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
    .campo-input {
      padding: 1rem 1rem 1rem;
      font-weight: 600;
      text-align: left;
      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1rem;
        font-family: "Poppins";
        font-weight: 600;
      }
    }
    .line-separator {
      width: 100%;
      height: 1px;
      background-color: #e0e0e0;
    }
  }
}
// class animation fade left
.fade-left {
  animation: fade-left 0.5s ease-in-out;
}

@keyframes fade-left {
  from {
    opacity: 0;
    transform: translateX(50%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// Class animation fade up
.fade-up {
  animation: fade-up 0.5s ease-in-out;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
